import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useOrientationCompile from "../../../Orientation";

import Element from "../../Element";
import Stats from "../Survival/Stats/Stats";
import ModalMessage from "../../../ModalMessage";
import AppStateContext from "../../../../appState/AppStateContext";
import combatActions from "../../../../assets/game/combat/combatActions";
import { Divider } from "@react-md/divider";

import aron0 from "../../../../assets/img/character/aron/0.png";
import aron1 from "../../../../assets/img/character/aron/1.png";
import aron2 from "../../../../assets/img/character/aron/2.png";
import aron3 from "../../../../assets/img/character/aron/3.png";

import eli0 from "../../../../assets/img/character/eli/0.png";
import eli1 from "../../../../assets/img/character/eli/1.png";
import eli2 from "../../../../assets/img/character/eli/2.png";
import eli3 from "../../../../assets/img/character/eli/3.png";

import elisa0 from "../../../../assets/img/character/elisa/0.png";
import elisa1 from "../../../../assets/img/character/elisa/1.png";
import elisa2 from "../../../../assets/img/character/elisa/2.png";
import elisa3 from "../../../../assets/img/character/elisa/3.png";

import alex0 from "../../../../assets/img/character/alex/0.png";
import alex1 from "../../../../assets/img/character/alex/1.png";
import alex2 from "../../../../assets/img/character/alex/2.png";
import alex3 from "../../../../assets/img/character/alex/3.png";

import Firebase from "../../../../FirebaseEngine/firebase";
import Objects from "../Survival/Objects/Objects";

const Wrapper = styled.div`
${props => `
  background-size: cover;
  display: flex;
  align-items:center;
  justify-content:left;
  max-width: 100%;
  max-height: 100%;
  height:${props.turn ? '100%' : "30%"};
  flex-direction:column;
  color: white;
  transition: 0.5s height;
  &>*{
      margin: 1vh 2vh;
  }
  @media (max-width: 426px) {
    flex-direction : column;
    overflow: auto;
    justify-content: normal;
}

`}`;

const Text = styled.div`
${props => `
    text-align : left;
    color: "white";
    font-size: 3vh;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`}`;

const DivImg = styled.img`
${props => `
    display: ${props.imageCha !== null ? "initial" : "none"};
    width:40vh;
`}`;


const DivPanel = styled.div`
    ${props => `
        background-color: ${props.state === "WIN" ? "rgba(92, 255, 0, 0.57);" :
            props.state === "GAMEOVER" ? "rgba(255, 0, 0, 0.57);" : "rgba(216, 216, 216, 0.5);"}
        border-radius: 8px;
        display: flex;
        ${useOrientationCompile()? "width : 100%" : "width : auto"};
        width : 100%;
        height:100%;
        max-width: 100%;
        max-height: 100%;
        flex-direction: row;
        padding: 2vh;
        grid-gap: 1em;
        align-items: center;
        transition:1s background-color;
    `}
`


const PRound = styled.p`
    ${props => `
        text-align:left;
        font-family: UniversalIgnorance;
        ${useOrientationCompile()? "font-size: 1.5em;" : "font-size: 1em;"};
        width:100%;
    `}
`


const ButtonDiv = styled.div`
${props => `
text-align: center;
border-radius: 0.7vh;
color: white;
background-color: ${props.action ? props?.special ? "goldenrod" : "red" : "grey"};
font-size: 3vh;
padding: 1vh;
height: fit-content;
border: 0.2vh solid transparent;
transition: all 0.1s ease 0s;
appearance: none;
cursor: pointer;
margin-top:1vh;
&:hover{
    background-color: #b50000;
}
@media (max-width: 426px) {
    font-size:1.5rem;
}
`}`;

const PanelCombat = (props) => {

    const [state, setState] = useState("INIT");
    const [stateDialogue, setStateDialogue] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalObject, setModalObject] = useState(false);
    const [text, setText] = useState(null);
    const [dataDialogue, setDataDialogue] = useState(null);
    const [imageCha, setImageCha] = useState(null);

    const appState = React.useContext(AppStateContext);
    const poder = appState.state.get("getPoder");
    const stats = appState.characters.get(appState.state.get("getPrimarycharacter"));
    const dialogue = appState.state.get("getDialogueCombat");
    const day = appState.state.get("dayChapter");
    const chapter = appState.state.get("numChapter");
    const progress = appState.state.get("progressDay");
    const name = appState.state.get("getName");

    const temporizadorId = useRef(null);
    let f = new Firebase();


    const loadJSModule = async () => {
        try {
            let TEMP_cap = chapter === "Prologo" ? 0 : chapter;
            let module = null;
            module = await import(`../../../../assets/game/dialogues/${TEMP_cap}/${day}_1_${progress}`);
            setDataDialogue(module.default);
        } catch (error) {
            console.error("Error al cargar el módulo JS: ", error);
            return null;
        }
    };

    const loadImgCharacter = (img) => {
        const path = img.split("/");
        switch (path[0]) {
            case "char":
                if(appState.state.get("getPrimarycharacter") === "aron"){
                    if (path[1]==="0") setImageCha(aron0);
                    if (path[1]==="1") setImageCha(aron1);
                    if (path[1]==="2") setImageCha(aron2);
                    if (path[1]==="3") setImageCha(aron3);
                }else{
                    if (path[1]==="0") setImageCha(eli0);
                    if (path[1]==="1") setImageCha(eli1);
                    if (path[1]==="2") setImageCha(eli2);
                    if (path[1]==="3") setImageCha(eli3);
                }
                break;
            case "elisa":
                if(path[1]==="0") setImageCha(elisa0)
                if(path[1]==="1") setImageCha(elisa1)
                if(path[1]==="2") setImageCha(elisa2)
                if(path[1]==="3") setImageCha(elisa3)
                break;
            case "alex":
                if(path[1]==="0") setImageCha(alex0)
                if(path[1]==="1") setImageCha(alex1)
                if(path[1]==="2") setImageCha(alex2)
                if(path[1]==="3") setImageCha(alex3)
            default:
                break;
        }
    }
    const changeName = (text) => {
        return text.replace("[nombre]", name);
    }
    const actionText = () => {
        if(dataDialogue[stateDialogue]?.IMG !== undefined){
            loadImgCharacter(dataDialogue[stateDialogue]?.IMG);
        }
        let TEMP_name = changeName(dataDialogue[stateDialogue]?.TEXT);
        setText(TEMP_name);
    }

    const selectedAttack = (key) => {
        if (stats.MP > combatActions?.[poder].ATK?.[key].MP) {
            props.params.attackCharacter(key, false); setModal(false);
        }
    }

    const getAttacks = () => {
        let views = [];
        if (poder !== undefined) {
            for (const key in combatActions?.[poder].ATK) {
                let atk = combatActions?.[poder].ATK?.[key];
                let actionOK = stats?.MP >= atk.MP;
                views.push(
                    <>
                        <ButtonDiv action={actionOK} special={atk?.special} onClick={() => { selectedAttack(key) }}>
                            {atk.name}
                        </ButtonDiv>
                        <div>Usarás {atk.MP} MP {atk?.HP !== undefined ? <>| <b>Te quita {atk.HP} HP</b></> : <></>}</div>
                        <Divider style={{ width: "100%" }} />
                    </>

                )
            };
        }
        return views;
    }

    useEffect(() => {
        if (dataDialogue !== null) {
            setImageCha(null);
            switch (dataDialogue[stateDialogue]?.ACTION) {
                case "TEXT":
                    actionText();
                    break;
                case "FINISH":
                    f.addDataGame({dialogue:false});
                    break;
                case "WAIT":
                    setText("");
                    break;
                default:
                    loadJSModule();
                    break;
            }


            if (dataDialogue[stateDialogue]?.TEMP > 0) {
                temporizadorId.current = setTimeout(() => {
                    setStateDialogue((prevStatus) => prevStatus + 1);
                }, dataDialogue[stateDialogue].TEMP * 1000);
            }

            return () => {
                if (temporizadorId.current) {
                    clearTimeout(temporizadorId.current);
                }
            };
        }
    }, [stateDialogue, dataDialogue]);
    
    useEffect(() => {
        if (appState !== null) {
            if (appState.state.get("getDialogueCombat")) {
                setState("DIALOGUE");
                loadJSModule();
            } else {
                setState("INIT");
            }
        }
        getAttacks();
    }, [appState]);


    return (
        <>
            <Element params={props.params}>
                <Wrapper params={props.params} turn={props.params.turn} >
                    {
                        state === "DIALOGUE" ? 
                            <>
                                <DivPanel>
                                    <DivImg src={imageCha} />
                                    <Text>{text}</Text>
                                </DivPanel>

                            </>
                        
                        :
                            <>
                                <PRound>RONDA {props.params.round} - {props.params.turn ? "TU TURNO" : "TURNO DEL ENEMIGO"}</PRound>
                                <DivPanel turn={props.params.turn} state={props.params.state}>
                                    {
                                        props.params.turn ?
                                            <>
                                                <div>
                                                    <ButtonDiv action={true} onClick={() => { setModal(true) }}>ATAQUE</ButtonDiv>
                                                    <ButtonDiv action={true} onClick={() => { props.params.defAction(false) }}>DEFENSA</ButtonDiv>
                                                    <ButtonDiv action={true} onClick={() => { setModalObject(true) }}>USAR OBJECTO</ButtonDiv>
                                                    {/*<ButtonDiv action={true}>HUIR</ButtonDiv>*/}
                                                </div>
                                                <div style={{ flex: 1, marginLeft: "1em" }}>
                                                    <Stats combat={true} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <h1 style={{ textAlign: "center", flex: 1 }}>{props.params.message}</h1>
                                            </>
                                    }

                                </DivPanel>
                            </>
                    }

                </Wrapper>
            </Element>
            <ModalMessage
                isShown={modal}
                setIsShown={setModal}
                title={"Ataques"}
                titleStyle={`font-size:2.1vh; margin-left:3vh;`}
                boxSize={`
                width: 40rem;
                background-color: rgba(216,216,216,1);
                `}
                styleMain={`
                border-bottom-left-radius:1vh;
                border-bottom-right-radius:1vh;
                height: 50vh !important;
                display: flex;
                text-align: justify !important;
                flex-direction:column;
                font-size: 1.5rem;
                padding: 1vw;
                `}
            >
                {getAttacks()}
            </ModalMessage>
            <ModalMessage
                isShown={modalObject}
                setIsShown={setModalObject}
                title={"Objectos"}
                titleStyle={`font-size:2.1vh; margin-left:3vh;`}
                boxSize={`
                width: 40rem;
                background-color: rgba(216,216,216,1);
                `}
                styleMain={`
                border-bottom-left-radius:1vh;
                border-bottom-right-radius:1vh;
                height: 50vh !important;
                display: flex;
                text-align: justify !important;
                flex-direction:column;
                font-size: 1.5rem;
                padding: 1vw;
                `}
            >
                <div>Solo puedes hacer uso de un objecto y tendra efecto en el turno del enemigo.</div>
                <Objects activeHover={true} combat={true} selectObject={props.params.selectObject} setSelectObject={props.params.setSelectObject}/>
            </ModalMessage>
        </>
    );
}
export default PanelCombat