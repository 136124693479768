import React, { useState } from 'react';
import MutableComponent from '../../../../composer/MutableComponent';
const GameDialogue = (props) => {

    const [history, setHistory] = useState ([]);
    const [waitSelects, setWaitSelects] = useState(null);

    const layoutDialogue = {
        "PORTRAIT": [
            ["dialogue", { position: "1/5/100/45", dialogue: props.dataGame.DIALOGUE, setHistory: setHistory,
                waitSelects: waitSelects, setWaitSelects: setWaitSelects
            }],
            ["history", { position: "80/5/99/45", history:history, waitSelects: waitSelects}],
            ["header", { position: "5/2/20/48" }],
        ],
        "LANDSCAPE": [
            ["dialogue", { position: "1/1/99/95", dialogue: props.dataGame.DIALOGUE, setHistory: setHistory,
                waitSelects: waitSelects, setWaitSelects: setWaitSelects
            }],
            ["history", { position: "5/40/15/55", history:history, waitSelects: waitSelects}],
            ["header", { position: "5/60/15/90" }],

        ]
    };



    return(
        <MutableComponent
            display={layoutDialogue}
        />
    )
}
export default GameDialogue;
