import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import Element from "../../Element.js";
import ModalMessage from '../../../ModalMessage.js';
import credits from "../../../../assets/credits.json"
import { Divider } from '@react-md/divider';

const Wrapper = styled.div`
${props => `
    text-align : left;
    color: white;
    font-size: 3vh;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    ${props.params.styles}
`}`;

const ButtonDiv = styled.div`
text-align: center;
border-radius: 0.7vh;
color: white;
background-color: red;
font-size: 2.5vh;
padding: 0.5vh;
width:100%;
border: 0.2vh solid transparent;
transition: all 0.1s ease 0s;
appearance: none;
cursor: pointer;
margin-top:0.5vh;
align-content: center;
flex: 1;
&:hover{
    background-color: #b50000;
}
@media (max-width: 426px) {
    font-size:1.5rem;
}
`

const Credits = (props) => {
  const [modal, setModal] = useState(false);

  const getCredits = () => {
    let view = [];
    credits.forEach(element => {
      view.push(
        <>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1" }}>{element.name} · By: {element.by}</div>
            <ButtonDiv onClick={()=>{window.open(element.link, '_blank');}}>Visitar</ButtonDiv>
          </div>
          <Divider style={{ width: "100%" }} />
        </>
      )
    });
    return view;
  }

  useEffect(() => {
    if (modal) {
      getCredits();
    }
  }, [modal]);
  return (
    <>
      <Element params={props.params}>
        <Wrapper params={props.params} onClick={(e) => setModal(true)}>Creditos</Wrapper>
      </Element>
      <ModalMessage
        isShown={modal}
        setIsShown={setModal}
        title={"Creditos"}
        titleStyle={`font-size:2.1vh; margin-left:3vh;`}
        boxSize={`
          width: 70rem;
          background-color: rgba(216,216,216,1);
        `}
        styleMain={`
          border-bottom-left-radius:1vh;
          border-bottom-right-radius:1vh;
          height: 40vh !important;
          display: flex;
          text-align:left;
          flex-direction:column;
          font-size: 1.5rem;
          padding: 1vw;
        `}

      >
        {getCredits()}
      </ModalMessage>
    </>

  );
}
export default Credits;

