import React, { useEffect, useState } from 'react';
import MutableComponent from '../../../../composer/MutableComponent';
import AppStateContext from '../../../../appState/AppStateContext';
import Firebase from '../../../../FirebaseEngine/firebase';
import actions from '../../../../assets/game/actions/actions';
const ChangeDay = (props) => {
    const appState = React.useContext(AppStateContext);
    const character = appState.state.get("getPrimarycharacter");
    const [dayNext, setDayNext] = useState("");

    const day = appState.state.get("dayChapter");
    const useObj = appState.state.get("getUseObj");

    const actionExecute = (action) => {
        const TEMP_FUN = actions[action[0]];
        TEMP_FUN(appState, action[1]);
    }

    const layoutDialogue = {
        "PORTRAIT": [
            ["text", { position: "50/10/55/40", text: "DÍA " + (dayNext), styles:"font-family: UniversalIgnorance; text-align:center; color: white; border-radius: 8px;"}],
        ],
        "LANDSCAPE": [
            ["text", { position: "30/15/80/85", text: "DÍA " + (dayNext) , styles:"font-family: UniversalIgnorance; text-align:center; color: red; font-size:20vh; border-radius: 8px;"}],
        ]
    };

    useEffect(()=>{
        setDayNext(day+1)
        setTimeout(()=>{
            let f = new Firebase();
            f.addDataGame({ "progress" : 0});
            f.addDataGame({ "day": day + 1});
            if(useObj){
                actionExecute([11,{hungry : 10}]);
                actionExecute([14,{thirsty : 5}]);
            }else{
                actionExecute([11,{hungry : 20}]);
                actionExecute([14,{thirsty : 10}]);  
            }
        },4000)
    },[])

    return(
        <MutableComponent
            display={layoutDialogue}
        />
    )
}
export default ChangeDay;