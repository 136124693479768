function StateObjects(state, parameters) {
    let [action, params] = parameters.split("/");
    state = state.objects;

    switch (action) {
        case "getFood":
            return getFood();
        case "getPOWER":
            return getPOWER();
        case "getLife":
            return getLife();
        case "getToy":
            return getToy();
    }
    function getFood() {
        return state[state.findIndex(obj => obj.hasOwnProperty('FOOD'))].FOOD;
    }
    function getPOWER() {
        return state[state.findIndex(obj => obj.hasOwnProperty('POWER'))].POWER;
    }
    function getLife() {
        return state[state.findIndex(obj => obj.hasOwnProperty('LIFE'))].LIFE;
    }
    function getToy() {
        return state[state.findIndex(obj => obj.hasOwnProperty('TOY'))].TOY;
    }

}
export default StateObjects;