import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player'

import music from "../../assets/sounds/green-sky.mp3";
import MutableComponent from "../../composer/MutableComponent";
import packageJson from '../../../package.json';


const  Home = () => {
    const [musica, setMusica] = useState(music);
    
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const loadSrcPublic = async (path) => {
        try {
            const response = await fetch(path);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.url;
            document.getElementById("id_wrapper").style.backgroundImage = `url(${data})`;
        } catch (error) {
            console.error("Error al cargar el archivo de capítulo: ", error);
            return null;
        }
    }
    
    useEffect(()=>{
        let dib = ["1_0_3", "1_0_4"];
        let num = getRandomInt(dib.length);
        loadSrcPublic(`/game/img/${dib[num]}.png`);
        document.getElementById("id_wrapper").style.backgroundSize = "cover";
    },[])


    let layout = {
        "PORTRAIT":
        [
            ["text", { position: "25/1/35/50", text:`LOS RECUERDOS`, styles: "font-size:10vw; text-align:center; font-family: UniversalIgnorance;" }],
            ["text", { position: "35/1/40/50", text:`DE LA OSCURIDAD`, styles: "font-size:10vw; text-align:center; font-family: UniversalIgnorance;" }],
            ["text", { position: "45/1/55/50", text:"CREADO POR MIGUEL A. LUJAN", styles: "font-size:3vh; text-align:center; font-family: Segoe UI;" }],
            ["panelStart" , {position: "60/9/80/45", onPlay:setMusica}],
            ["header" , {position: "5/5/15/45"}],
            ["credits", {position: "95/3/100/20"}],
            ["text", { position: "95/40/100/49", text:"v. " + packageJson.version, styles: "font-size:2vh;" }],
        ],
        "LANDSCAPE":[
            ["text", { position: "25/1/35/100", text:`LOS RECUERDOS DE LA OSCURIDAD`, styles: "font-size:8vh; text-align:center; font-family: UniversalIgnorance;" }],
            ["text", { position: "40/1/50/100", text:"CREADO POR MIGUEL A. LUJAN", styles: "font-size:3vh; text-align:center; font-family: Segoe UI;" }],
            ["panelStart" , {position: "70/20/80/80", onPlay:setMusica}],
            ["header" , {position: "5/60/15/90"}],
            ["credits", {position: "95/3/100/13"}],
            ["text", { position: "95/95/100/100", text:"v. " + packageJson.version, styles: "font-size:2vh;" }],
        
        ]
    }

    return (
        <>
            <MutableComponent 
                display={layout}
            />
            <ReactPlayer
                autoPlay={true}
                url={musica !== null ? [musica] : null}
                playing={true}
                playbackRate={1}
                loop={true}
                style={{ display: "none" }}
            />
        </>
    );
}
export default Home;