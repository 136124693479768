import React, { useEffect, useRef, useState } from 'react';
import GameComponent from './GameComponent';
import AppStateContext from '../../appState/AppStateContext';
import Firebase from '../../FirebaseEngine/firebase';
import actions from '../../assets/game/actions/actions';
import ReactPlayer from 'react-player'

const GameEngine = () => {

    const appState = React.useContext(AppStateContext);
    const mode = appState.state.get("gameMode");
    const day = appState.state.get("dayChapter");
    const chapter = appState.state.get("numChapter");
    const progress = appState.state.get("progressDay");

    const [dataGameChapter, setDataGameChapter] = useState(0)
    const [state, setState] = useState(mode);
    const [musica, setMusica] = useState(null);
    const temporizadorId = useRef(null);
    const f = new Firebase();


    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const actionExecute = (action) => {
        const TEMP_FUN = actions[action[0]];
        TEMP_FUN(appState, action[1]);
    }


    const engineCombat = () => {
        if (appState !== null & dataGameChapter !== 0) {
            let statsCharacter = appState.characters.get(appState.state.get("getPrimarycharacter"));
            if (appState.state.get("getTurnCombat") === undefined && statsCharacter != null) {
                console.log(statsCharacter);

                actionExecute([0, { vida: statsCharacter.statsMax.HP }]);
                actionExecute([6, { mp: statsCharacter.statsMax.MP }]);
                if (dataGameChapter?.dialogue) {
                    f.addDataGame({ dialogue: true });
                }

                f.addDataGameCharacterPrincipal(dataGameChapter?.enemy, "enemy");
                let statsEnemy = dataGameChapter?.enemy;
                let luk = (statsEnemy.luk * 1000) - (getRandomInt(statsCharacter.luk * 1000) / (statsCharacter.luk - (statsCharacter.luk / 2)));
                if (((statsEnemy.luk * 1000) - (statsEnemy.luk * 10)) < luk) {
                    f.addDataGame({ roundCombat: 1, turnCombat: true, stateCombat: "INIT", objectsEnemy: statsEnemy.luk });
                } else if (((statsEnemy.luk * 1000) - (statsEnemy.luk * 500)) < luk) {
                    f.addDataGame({ roundCombat: 1, turnCombat: true, stateCombat: "INIT", objectsEnemy: statsEnemy.luk * 2 });
                } else if (((statsEnemy.luk * 1000) - (statsEnemy.luk * 750)) < luk) {
                    f.addDataGame({ roundCombat: 1, turnCombat: true, stateCombat: "INIT", objectsEnemy: statsEnemy.luk * 3 });
                } else {
                    f.addDataGame({ roundCombat: 1, turnCombat: true, stateCombat: "INIT", objectsEnemy: statsEnemy.luk * 4 });
                }
            }
        }

    }

    const engineDialogue = () => {
        document.getElementById("id_wrapper").style.backgroundColor = "black";
    }

    const loadJSON = async () => {
        try {
            if (chapter !== null) {
                let TEMP_cap = chapter === "Prologo" ? 0 : chapter;
                const response = await fetch(`/game/chapters/${TEMP_cap}.json`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDataGameChapter(data[day][progress]);
                f.addDataGame({ state: data[day][progress].STATE })
                return data;
            }
        } catch (error) {
            console.error("Error al cargar el archivo de capítulo: ", error);
            return null;
        }
    };


    const loadSrcPublic = async (path) => {
        try {
            const response = await fetch(path);
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.url;
            const wrapper = document.getElementById("id_wrapper");
            if (wrapper) {
                wrapper.style.backgroundImage = `url(${data})`;
                wrapper.style.backgroundSize = "cover";
            } else {
                console.error("Element with id 'id_wrapper' not found");
            }
        } catch (error) {
            console.error("Error al cargar el archivo de capítulo: ", error);
            return null;
        }
    }

    const loadSrcPublicMusic = async (path, tag) => {
        try {
            const response = await fetch(path);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.url;
            setMusica(data);
        } catch (error) {
            console.error("Error al cargar el archivo de capítulo: ", error);
            return null;
        }
    }
    useEffect(() => {
        loadJSON();
    }, [appState])

    useEffect(() => {
        setState(mode);
        if (dataGameChapter !== 0) {
            temporizadorId.current = setTimeout(() => {
                if (mode === "WAIT" && progress !== undefined) {
                    setMusica("");
                    f.removeDataGame(["turnCombat", "stateCombat", "roundCombat", "objectsEnemy", "dialogue", "defenseEnemyCombat"]);
                    f.removeDataGame(["lastDecision", "abrazo"]);
                    document.getElementById("id_wrapper").style.background = "";
                } else {
                    loadSrcPublicMusic(dataGameChapter?.music);
                    document.getElementById("id_wrapper").style.backgroundImage = "";
                    document.getElementById("id_wrapper").style.backgroundSize = "cover";
                    if (mode === "DIALOGUE") {
                        engineDialogue();
                    } else if (mode === "COMBAT") {
                        engineCombat();
                    } else if (mode === "SURVIVAL") {
                        if (dataGameChapter?.BACKGROUND !== undefined) {
                            loadSrcPublic(`/game/img/${dataGameChapter?.BACKGROUND}`);
                        }
                        document.getElementById("id_wrapper").style.backgroundSize = "cover";
                    }
                }
            }, 3000);
        }
        return () => {
            if (temporizadorId.current) {
                clearTimeout(temporizadorId.current);
            }
        };
    }, [mode, progress]);

    useEffect(() => {
        if (mode === "WAIT") {
            let dib = ["1_0_0", "1_0_1", "1_0_3", "1_0_4"];
            let num = getRandomInt(dib.length);
            loadSrcPublic(`/game/img/${dib[num]}.png`);
            document.getElementById("id_wrapper").style.backgroundSize = "cover";
            temporizadorId.current = setTimeout(() => {
                f.addDataGame({ progress: progress + 1 });
            }, 3000);
        }
        return () => {
            if (temporizadorId.current) {
                clearTimeout(temporizadorId.current);
            }
        };
    }, [mode]);

    return (
        <>
            {dataGameChapter === 0 ? <div style={{ width: "100vw", height: "100vh", textAlign: "center", alignContent: "center" }}>
                <h1 style={{ color: "white" }}>CARGANDO A LA BASE DE DATOS...</h1>
            </div> :
                <GameComponent
                    dataGame={dataGameChapter}
                    state={state}
                />
            }

            <ReactPlayer
                autoPlay={true}
                url={musica !== null ? [musica] : null}
                playing={true}
                playbackRate={1}
                loop={true}
                style={{ display: "none" }}
            />
        </>

    );
}

export default GameEngine;
