import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import AppStateContext from '../../../../../appState/AppStateContext';
import aron2 from "../../../../../assets/img/character/aron/2.png";
import eli2 from "../../../../../assets/img/character/eli/2.png";
import elisa2 from "../../../../../assets/img/character/elisa/2.png";
import alex2 from "../../../../../assets/img/character/alex/2.png";
import Firebase from '../../../../../FirebaseEngine/firebase';

const PanelDialogue = (props) => {

    const [chats, setChats] = useState(null);
    const [selectChat, setSelectChat] = useState(null);


    const appState = React.useContext(AppStateContext);
    const day = appState.state.get("dayChapter");
    const chapter = appState.state.get("numChapter");
    const progress = appState.state.get("progressDay");
    const character = appState.state.get("getPrimarycharacter");

    const ButtonDiv = styled.div`
    ${props => `
    text-align: center;
    border-radius: 0.7vh;
    color: white;
    background-color:  red;
    font-size: 3vh;
    padding: 1vh;
    width: ${props.character ? "min-content" : "100%"};
    border: 0.2vh solid transparent;
    transition: all 0.1s ease 0s;
    appearance: none;
    cursor: pointer;
    margin-top:1vh;
    ${props.character ? "margin : 1vh 1vh 0;" : ""};
    ${props.character ? "display : flex;" : ""};
    ${props.character ? "align-items: center;" : ""};
    ${props.character ? "flex-direction:column;" : ""};

    ${props.character ? "flex : 1; " : ""};
    &:hover{
        background-color: #b50000;
    }
    @media (max-width: 426px) {
        font-size:1.5rem;
    }
    `}`;


    const DivPanel = styled.div`
    ${props => `
        background-color: rgba(216, 216, 216, 0.5);
        border-radius: 8px;
        display: flex;
        width : 100%;
        height:100%;
        max-width: 100%;
        max-height: 70vh;
        flex-direction: column ;
        padding: 2vh;
        grid-gap: 1em;
    `}
`

    const DivImg = styled.img`
${props => `
    display: initial;
    width:40vh;
`}`;
    const loadJSModule = async () => {
        try {
            let TEMP_cap = chapter === "Prologo" ? 0 : chapter;
            let module = null;
            module = await import(`../../../../../assets/game/chatCharacters/${TEMP_cap}_${day}_${progress}`);
            setChats(module.default);
            console.log(chats);
        } catch (error) {
            console.error("Error al cargar el módulo JS: ", error);
            return null;
        }
    };

    const saveChat = (element) =>{
        let f = new Firebase();
        let TEMP_cap = chapter === "Prologo" ? 0 : chapter;
        f.addDataGameDecision({"lastDecision": `${TEMP_cap}_${day}_${progress}_${element}`},TEMP_cap,day, progress)
    }

    const loadChats = () => {
        let view = [];
        if (chats !== null) {
            Object.keys(chats).forEach(element => {
                switch (element) {
                    case "aron":
                        if (character !== element)
                            view.push(
                                <>
                                    <ButtonDiv character={true} onClick={() => { setSelectChat(element); saveChat(element) }}>
                                        <DivImg src={aron2} />
                                        Leo
                                    </ButtonDiv>
                                </>
                            )
                        break;
                    case "eli":
                        if (character !== element)
                            view.push(
                                <>
                                    <ButtonDiv character={true} onClick={() => { setSelectChat(element); saveChat(element) }}>
                                        <DivImg src={eli2} />
                                        Ana
                                    </ButtonDiv>
                                </>

                            )
                        break;
                    case "elisa":
                        view.push(
                            <>
                                <ButtonDiv character={true} onClick={() => { setSelectChat(element); saveChat(element) }}>
                                    <DivImg src={elisa2} />
                                    Elisa
                                </ButtonDiv>
                            </>
                        )
                        break;
                    case "alex":
                        view.push(
                            <>
                                <ButtonDiv character={true} onClick={() => { setSelectChat(element); saveChat(element)}}>
                                    <DivImg src={alex2} />
                                    Alex
                                </ButtonDiv>
                            </>
                        )
                        break;
                    default:
                        break;
                }

            });;
        }

        return view;
    }


    useEffect(() => {
        if (chats !== null) {
            loadChats();
            console.log(chats);
        }
    }, [chats])


    useEffect(() => { loadJSModule() }, [])

    return (
        <DivPanel style={{ overflow: "auto" }}>
            {selectChat !== null ? <ButtonDiv style={{ width: "fit-content" }} onClick={() => { setSelectChat(null) }}>Atras</ButtonDiv> : <ButtonDiv style={{ width: "fit-content" }} onClick={() => { props.setSelectOption("INIT") }}>Pantalla inicial</ButtonDiv>}
            {selectChat === null ?
                <div style={{ display: "flex" }}>
                    {loadChats()}
                </div>
                :
                <>
                    {selectChat === "aron" ? <DivImg src={aron2} /> : selectChat === "eli" ? <DivImg src={eli2} /> : selectChat === "elisa" ? <DivImg src={elisa2} /> : <DivImg src={alex2} />}
                    <h2 style={{ textAlign: "left" }}>{chats?.[selectChat]}</h2>

                </>
            }
        </DivPanel>
    );
}
export default PanelDialogue;